import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import Header from 'components/headers/light.js'
import Footer from 'components/footers/FiveColumnWithBackground.js'
import { SectionHeading } from 'components/misc/Headings'

const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-gray-900 mb-10`
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`

export default ({ headingText = 'Privacy Policy' }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h2>Welcome to VidGen</h2>
            <p>
              At VidGen, safeguarding your privacy is our top priority. This
              Privacy Notice outlines our information collection and usage
              practices. By downloading, accessing, or using our application,
              you explicitly acknowledge and agree to the stipulations detailed
              in this Notice. If you disagree with any aspect of this policy, we
              kindly request that you refrain from utilizing our services.
            </p>

            <h2>Contact Us</h2>
            <p>
              If you have any queries or require additional information about
              our privacy practices, feel free to{' '}
              <a href='mailto:support@tryvidgen.com'>contact us</a>.
            </p>

            <h2>Facial Data Handling Policy</h2>

            <h3>Data Collection</h3>
            <p>
              You have the option to upload a photograph of a human face to
              generate a digital video. VidGen employs advanced text-to-speech
              and real-time face animation to produce immersive and lifelike
              conversational videos.
            </p>

            <h3>Purpose and Process</h3>
            <p>To utilize the photo-to-video feature, follow these steps:</p>
            <ol>
              <li>Input the script or text for the video.</li>
              <li>
                Upload the desired human face photo, securely stored on your
                personal device.
              </li>
              <li>
                Click 'submit,' prompting our server to analyze the face photo
                as the video template.
              </li>
              <li>
                The server generates a video based on the human face photo and
                provided text.
              </li>
            </ol>

            <h2>Log Files</h2>
            <p>
              VidGen follows standard log file procedures, collecting
              information such as IP addresses, browser type, date and time
              stamps, and user movement within the app. This data, devoid of
              personally identifiable information, aids in trend analysis, app
              administration, and demographic information gathering.
            </p>

            <h2>Advertising Partners</h2>

            <h3>Third-Party Privacy Policies</h3>
            <p>
              Certain advertisers in our app may use cookies and web beacons.
              Please refer to the Privacy Policies of our advertising partners
              listed below for their specific data usage practices:
            </p>
            <ul>
              <li>
                <a href='https://policies.google.com/technologies/ads'>
                  Google
                </a>
              </li>
            </ul>

            <h3>Third-Party Privacy Policies</h3>
            <p>
              Third-party ad servers may use technologies like cookies or
              beacons for advertising campaigns and content personalization.
              VidGen lacks control over these technologies and suggests
              reviewing the respective Privacy Policies of third-party ad
              servers for detailed information and opt-out options.
            </p>

            <h2>Children's Information</h2>
            <p>
              To protect children online, we urge parents to supervise their
              online activities. VidGen does not knowingly collect Personal
              Identifiable Information from children under 13. If such
              information is inadvertently provided, please{' '}
              <a href='mailto:support@tryvidgen.com'>contact us</a>, and we will
              promptly remove it from our records.
            </p>

            <h2>Online Privacy Policy Only</h2>
            <p>
              This Privacy Notice applies exclusively to online activities
              within the VidGen app and does not extend to information collected
              offline or through channels other than the app.
            </p>

            <h2>Consent</h2>
            <p>
              By using VidGen, you affirmatively consent to our Privacy Notice
              and agree to its Terms and Conditions.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  )
}
