import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import Header from 'components/headers/light.js'
import Footer from 'components/footers/FiveColumnWithBackground.js'
import { SectionHeading } from 'components/misc/Headings'

const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-gray-900 mb-10`
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`

export default ({ headingText = 'Terms of Use' }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              By downloading or using the VidGen AI App, these terms will
              automatically apply to you – you should make sure therefore that
              you read them carefully before using the app. You’re not allowed
              to copy or modify the app, any part of the app, or our trademarks
              in any way. You shouldn’t try to translate the app into other
              languages or make derivative versions. The app itself, and all the
              trademarks, copyright, database rights, and other intellectual
              property rights related to it, still belong to VidGen AI App.
            </p>
            <p>
              VidGen AI App is committed to ensuring that the app is as useful
              and efficient as possible. For that reason, we reserve the right
              to make changes to the app or to charge for its services, at any
              time and for any reason. We will never charge you for the app or
              its services without making it very clear to you exactly what
              you’re paying for.
            </p>
            <p>
              You should be aware that there are certain things that AI Essay
              Writing App will not take responsibility for. Certain functions of
              the app will require the app to have an active internet
              connection. The connection can be Wi-Fi or provided by your mobile
              network provider, but VidGen AI App cannot take responsibility for
              the app not working at full functionality if you don’t have access
              to Wi-Fi, and you don’t have any of your data allowance left.
            </p>
            <p>
              If you’re using the app outside of an area with Wi-Fi, you should
              remember that the terms of the agreement with your mobile network
              provider will still apply. As a result, you may be charged by your
              mobile provider for the cost of data for the duration of the
              connection while accessing the app, or other third-party charges.
              In using the app, you’re accepting responsibility for any such
              charges, including roaming data charges if you use the app outside
              of your home territory (i.e. region or country) without turning
              off data roaming. If you are not the bill payer for the device on
              which you’re using the app, please be aware that we assume that
              you have received permission from the bill payer for using the
              app.
            </p>
            <p>
              Along the same lines, VidGen AI App cannot always take
              responsibility for the way you use the app i.e. You need to make
              sure that your device stays charged – if it runs out of battery
              and you can’t turn it on to avail of the Service, VidGen AI App
              cannot accept responsibility.
            </p>

            <p>
              With respect to VidGen AI App's responsibility for your use of the
              app, when you’re using the app, it’s important to bear in mind
              that although we endeavor to ensure that it is updated and correct
              at all times, we do rely on third parties to provide information
              to us so that we can make it available to you. AI Essay Writing
              App accepts no liability for any loss, direct or indirect, you
              experience as a result of relying wholly on this functionality of
              the app.
            </p>
            <h1>About One-Time Premium Payments</h1>
            <p>
              We're delighted to offer you the option of a one-time premium
              payment, which grants you access to an exclusive premium
              subscription. With this special subscription, you can enjoy all
              the incredible features and benefits that VidGen has to offer.
              Please note that while the VidGen team has committed to
              maintaining the app's availability, there may be rare instances
              where circumstances require it to be removed from the store in the
              future. However, rest assured that all rights are reserved, and we
              are dedicated to ensuring a seamless and enjoyable experience for
              our valued users. Your support means the world to us, and we look
              forward to providing you with exceptional service and an
              outstanding VidGen experience. Thank you for choosing us!
            </p>
            <h1>About Restricted Content</h1>
            <p>
              At VidGen, we firmly believe in fostering a safe and inclusive
              community for all our users. Therefore, we want to emphasize that
              our platform strictly prohibits the generation of any hate speech
              or sexual content. We have implemented robust content filters and
              moderation mechanisms to ensure that such inappropriate materials
              do not find their way into our app. Our commitment to promoting a
              positive and respectful environment extends to every aspect of
              VidGen's functionality. We actively encourage creativity and
              expression within the boundaries of respect and kindness. Rest
              assured that we are vigilant in upholding these standards to
              create an enjoyable and secure experience for everyone who uses
              VidGen. Together, let's continue to nurture a community that
              celebrates creativity while respecting the rights and dignity of
              all individuals.
            </p>
            <h1>Contact Us</h1>
            <p> support@tryvidgen.com</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  )
}
